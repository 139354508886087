<template>
  <div class="after-content" v-if="IdValue === 3">
    <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" status-icon>
      <!-- <el-form-item label="请选择订单" prop="orderNumber" class="after-d">
        <el-select
          size="large"
          v-model="ruleForm.orderNumber"
          filterable
          clearable
          placeholder="请选择或输入订单编号"
          @change="handelChangValue($event)"
        >
          <el-option
            v-for="item in optionsList"
            :key="item.index"
            :label="item"
            :value="item"
          />
        </el-select>
      </el-form-item> -->
      <el-form-item label="请输入主机SN" prop="masterSnCode" class="after-c">
        <el-input
          class="numberInput"
          v-model="ruleForm.masterSnCode"
          placeholder="请输入主机SN号"
          type="text"
        />
        <div class="snsinfo" @mouseenter="handleEnter1" @mouseleave="handleLeave1">如何查询主机SN？</div>
        <div class="snsinfo1" v-if="backtwo">
          <img src="./images//SN.png" >
          <div class="snsinfo1-1">1.可从机身侧面的标签上获取SN码</div>
          <div class="snsinfo1-2">2.如机身标签磨损，请联系在线客服咨询如何获取SN码</div>
        </div>
      </el-form-item>
      <div class="detail-content" v-if="detailValue.orderNumber">
        <div class="detail-top">
          <p class="top-1">
            <span>订单编号：</span><span>{{ detailValue.orderNumber }}</span>
          </p>
          <p class="top-2">
            <img src="./images/wc.png" alt="" /><span>已完成</span>
          </p>
        </div>
        <div class="detail-btn">
          <div class="btn-1">
            <img :src="detailValue.picture" alt="" />
          </div>
          <div class="btn-2">
            <div class="bnt2-1">
              <span>{{ detailValue.productName }}</span>
            </div>
            <div class="btn-2-2">
              <p class="btn-2-3">
                <span>商品单价：</span><span>{{ detailValue.price }}</span>
              </p>
              <p class="btn-2-4">
                <span>商品数量：</span><span>{{ detailValue.count }}</span>
              </p>
              <p class="btn-2-5">
                <span>订单金额：</span><span>￥{{ detailValue.amount }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-else></div>
      <el-form-item label="售后类型" prop="type" class="after-l">
        <ui v-for="(item, index) in saleType" :key="item.index" class="l-li">
          <li
            @click="selectH(item, index)"
            :class="{ active: index === isCheck }"
          >
            <p class="l-title">
              <img
                v-if="item.typeName == '申请维修'"
                src="./images/sqwx.png"
                alt=""
              />
              <img
                v-if="item.typeName == '申请换货'"
                src="./images/sqhh.png"
                alt=""
              />
              <img
                v-if="item.typeName == '申请退货'"
                src="./images/sqth.png"
                alt=""
              />
              <img
                v-if="item.typeName == '申请补发'"
                src="./images/SQBF.png"
                alt=""
              />
              <span>{{ item.typeName }}</span>
              <span class="imgVal"></span>
            </p>
          </li>
        </ui>
      </el-form-item>
      <el-form-item
        label="申请原因"
        prop="reason"
        class="after-s"
        v-if="saleType"
      >
        <ui
          v-for="(j, index) in saleType[isCheck]?.reason"
          :key="j.index"
          class="ui-l"
        >
          <li @click="selectS(j, index)" :class="{ active: index === selecS }">
            <p class="l-title">
              <span>{{ j }}</span>
            </p>
          </li>
        </ui>
      </el-form-item>
      <div style="display: flex">
        <img
          src="./images/xh-1.png"
          alt=""
          style="width: 6px; height: 6px; position: relative; top: 20px"
        />
        <el-form-item label="报修人" class="after-s-s" style="margin-left: 5px">
          <div class="after-s-s-1">
	          <div style="display: flex;margin-top: 23px;">
		          <el-form-item label="联系人" prop="repairer">
			          <el-input
					          class="textInput"
					          v-model="ruleForm.repairer"
					          placeholder="请填写您的姓名"
					          type="text"
			          />
		          </el-form-item>
		          <el-form-item label="联系方式" prop="repairerPhone">
			          <el-input
					          class="numberInput"
					          v-model="ruleForm.repairerPhone"
					          placeholder="请填写您的联系方式"
			          />
		          </el-form-item>
	          </div>
	          <div style="margin-top: 23px;">
		          <el-form-item label="报修地址" prop="repairerAddress">
			          <el-input
					          class="addressInput"
					          v-model="ruleForm.repairerAddress"
					          placeholder="请填写您的报修地址"
			          />
		          </el-form-item>
	          </div>
          </div>
        </el-form-item>
      </div>
      <el-form-item label="上传图片 " prop="mediaUrl" class="after-up">
        <span>（请上传商品故障图片，最多上传五张图片）</span>
        <div style="width: 680px">
          <el-upload
            v-model:file-list="fileList"
            :action="uploadBrand.url"
            :headers="uploadBrand.headers"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :on-success="uploadSuccessOne"
            :before-upload="beforeLogoUpload"
          >
            <el-icon><Plus /></el-icon>
          </el-upload>

          <el-dialog v-model="dialogVisible">
            <img :src="dialogImageUrl" alt="Preview Image" />
          </el-dialog>
        </div>
      </el-form-item>
      <el-form-item label="补充说明" prop="comment" class="after-desc">
        <el-input
          v-model="ruleForm.comment"
          type="textarea"
          placeholder="选填，最多支持200字"
          maxlength="200"
          show-word-limit
        />
      </el-form-item>
      <el-form-item>
        <el-button @click="submitForm(ruleFormRef)" class="btn-btn">
          提交申请
        </el-button>
        <!-- <el-button @click="resetForm(ruleFormRef)">重置</el-button> -->
      </el-form-item>
    </el-form>
  </div>
  <index v-if="IdValue === 1" />
</template>

<script setup>
import { reactive, ref, onMounted, defineProps, watch, defineEmits } from "vue";
import {
  AfterList,
  AfterDetail,
  PostSmit,
  AfterSaleReason,
  DeleteImg,
  DeleteImgs,
} from "../../../api/after/index.js";
import index from "../index";
import { Plus } from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";
import { getToken } from "@/utils/auth";
import emitter from "@/utils/eventBus";
const props = defineProps({
  AfterApply: {
    type: Object,
  },
});
// http://172.16.105.44:80
// process.env.VUE_APP_BASE_API +
// https://testshop.iningmei.com
const URL = ref("https://testshop.iningmei.com" + "/order");
const fileList = ref([]); // 页面上展示的图片
//  process.env.VUE_APP_BASE_API +
const uploadBrand = reactive({
  headers: { Authorization: "Bearer " + getToken() },
  url: "/order/common/uploads",
});
const isCheck = ref(0);
const selectH = (val, index) => {
  console.log(val);
  isCheck.value = index;
  ruleForm.value.type = val.type;
};
const selecS = ref();
const selectS = (val, index) => {
  selecS.value = index;
  ruleForm.value.reason = val;
};
const ruleFormRef = ref();
const backtwo = ref(false)
const handleEnter1 = (res) => {
  backtwo.value = true;
};
const handleLeave1 = (res) => {
  backtwo.value = false;
};
const ruleForm = ref({
  type: "maintenance", // 类型
  reason: null, // 申请原因
  orderNumber: null, // 订单编号
  masterSnCode: null, // 订单编号
  mediaUrl: null, // 图片
  comment: null, // 补充说明
  id: null,
  repairer: null,
  repairerPhone: null,
	repairerAddress: null, // 报修地址
});
const IdValue = ref(3);
const fileListTemp = ref([]);
watch(
  props.AfterApply,
  (newValue) => {
    if (newValue) {
      ruleForm.value.type = newValue.type;
      ruleForm.value.reason = null;
      ruleForm.value.orderNumber = newValue.orderNumber;
      ruleForm.value.comment = newValue.comment;
      ruleForm.value.id = newValue.id;
      ruleForm.value.mediaUrl = newValue.mediaUrl
        ? newValue.mediaUrl.split(",").filter((item) => item)
        : "";
      if (ruleForm.value.mediaUrl) {
        ruleForm.value.mediaUrl.forEach((item, index) => {
          fileList.value.push({
            name: index + ".png",
            url: URL.value + item,
            tmp: item,
          });
        });
        fileList.value.forEach((item) => {
          fileListTemp.value.push(item.tmp);
        });
        AfterDetail(newValue.orderNumber).then((res) => {
          console.log(res);
          if (res.code === 200) {
            detailValue.value = res.data;
          }
        });
      }
    }
  },
  { immediate: true, deep: true }
);

// 验证
const rules = reactive({
  masterSnCode: [
    {
      required: true,
      message: "主机SN不能为空",
      trigger: "change",
    },
  ],
  reason: [
    {
      required: true,
      message: "申请原因不能为空",
      trigger: "change",
    },
  ],
  type: [
    {
      required: true,
      message: "类型不能为空",
      trigger: "change",
    },
  ],
  mediaUrl: [
    {
      required: true,
      message: "图片不能为空",
      trigger: "change",
    },
  ],
  repairer: [
    {
      required: true,
      message: "联系人不能为空",
      trigger: "change",
    },
  ],
  repairerPhone: [
    {
      required: true,
      message: "联系电话不能为空",
      trigger: "change",
    },
  ],
	repairerAddress: [
		{
			required: true,
			message: "报修地址不能为空",
			trigger: "change",
		},
	]
});
//const imgArrayData = ref([]);
// const imgArrayData1 = ref([]);
// 提交申请确认
const emit = defineEmits(["changeId"]);
const successValData = ref(null);
const submitForm = async (formEl) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      if (!ruleForm.value.id) {
        // ruleForm.value.mediaUrl = imgArrayData1.value.join(",");
        console.log(ruleForm.value);
        PostSmit(ruleForm.value).then((res) => {
          if (res.code === 200) {
            successValData.value = res.data;
            IdValue.value = 1;
            ElMessage({
              message: "提交成功！",
              type: "success",
            });
          } else {
            ElMessage({
              message: res.message,
              type: "error",
            });
          }
        });
      } else {
        console.log(ruleForm.value.mediaUrl, "入口");
        if (ruleForm.value.mediaUrl instanceof Array) {
          ruleForm.value.mediaUrl = ruleForm.value.mediaUrl.join(",");
        }
        console.log(ruleForm.value, "提交");
        PostSmit(ruleForm.value).then((res) => {
          if (res.code === 200) {
            successValData.value = res.data;
            // emit("changeId", 1);
            IdValue.value = 1;
            ElMessage({
              message: "提交成功！",
              type: "success",
            });
          } else {
            ElMessage({
              message: res.message,
              type: "error",
            });
          }
        });
      }
    } else {
      console.log("error submit!", fields);
    }
  });
};
// 重置
// const resetForm = (formEl) => {
//   if (!formEl) return;
//   formEl.resetFields();
// };
const dialogImageUrl = ref("");
const dialogVisible = ref(false);

// 已完成订单编号
const optionsList = ref([]);
/*const getAfterList = async () => {
  try {
    const res = await AfterList();
    if (res.code === 200) {
      optionsList.value = res.data;
    }
  } catch (error) {
    console.log(error);
  }
};*/
// 根据订单号查询
const detailValue = ref({});
const orderNumber = ref();
const handelChangValue = async (val) => {
  orderNumber.value = val;
  try {
    const res = await AfterDetail(orderNumber.value);
    if (res.code === 200) {
      detailValue.value = res.data;
    }
  } catch (error) {
    console.log(error);
  }
};
// 图片上传显示大小
const beforeLogoUpload = (file) => {
  const isJPG = file.type.indexOf("image/") > -1;
  const isLt2M = file.size / 1024 / 1024 < 10;
  if (!isJPG) {
    ElMessage({
      message: "文件格式错误，请上传图片类型,如：JPG，PNG后缀的文件!",
      type: "error",
    });
  }
  if (!isLt2M) {
    ElMessage({
      message: "上传图片大小不能超过 10MB!",
      type: "error",
    });
  }
  if (fileList.value.length > 4) {
    ElMessage({
      message: "最多上传五张图片",
      type: "error",
    });
	  //console.log(fileList.value,'上传前')
		return false
    //fileList.value.pop();
  }
  return isJPG && isLt2M;
};

const tempFile = ref([]);
// 上传成功
const uploadSuccessOne = (file) => {
	console.log(fileList.value,'上传成功，页面上的图片')
  //console.log(mediaUrlImgList.value, "2222222");
  if (
    mediaUrlImgList.value != null &&
    ruleForm.value.mediaUrl instanceof Array
  ) {
    if (file.code === 200) {
      tempFile.value.push(file.data.fileNames);
      //console.log(tempFile.value,'===上传成功===');
      ruleForm.value.mediaUrl = mediaUrlImgList.value.concat(tempFile.value).toString();
	    console.log(ruleForm.value.mediaUrl, "二次保存");
    }else{
			ElMessage({
				message: file.message,
				type: "error",
			});
			fileList.value.pop();
	    console.log(fileList.value,'上传失败，页面上的图片')
    }
  } else {
    if (file.code === 200) {
      console.log(ruleForm.value.mediaUrl);
      tempFile.value.push(file.data.fileNames);
      ruleForm.value.mediaUrl = mediaUrlImgList.value
        .concat(tempFile.value)
        .toString();
      // imgArrayData1.value = ruleForm.value.mediaUrl.concat(tempFile.value);
      console.log(ruleForm.value.mediaUrl, "第一次");
    }else{
	    ElMessage({
		    message: file.message,
		    type: "error",
	    });
	    fileList.value.pop();
	    console.log(fileList.value,'上传失败，页面上的图片')
    }
  }
};

const handlePictureCardPreview = (uploadFile) => {
  //console.log(uploadFile);
  dialogImageUrl.value = uploadFile.url;
  fileList.value.push();
  dialogVisible.value = true;
};
// 删除图片
const mediaUrlImgList = ref([]);
const handleRemove = async (file, uploadFile) => {
  console.log(file,'===删除 file===');
  if (file.response) {
    console.log(file.response.data.fileNames,'===file.response.data.fileNames===');
    try {
      let res = await DeleteImg(file.response.data.fileNames);
      if (res.code === 200) {
	      tempFile.value.splice(
			      tempFile.value.indexOf(file.response.data.fileNames),
			      1
	      );
        ruleForm.value.mediaUrl = tempFile.value.toString();
        console.log(ruleForm.value.mediaUrl,'===ruleForm.value.mediaUrl==');
      }
    } catch (error) {
      console.log(error);
    }
  } else {
    let dataValue = ref({
      id: props.AfterApply.id,
      mediaUrl: props.AfterApply.mediaUrl,
      mediaUrlDelete: file.tmp,
    });
    try {
      console.log(dataValue.value,'===dataValue.value===');
      let res = await DeleteImgs(dataValue.value);
      if (res.code === 200) {
        const fileTmp = ref(dataValue.value.mediaUrlDelete);
        console.log(ruleForm.value.mediaUrl,'===ruleForm.value.mediaUrl==');
        ruleForm.value.mediaUrl.splice(fileTmp, 1);
        mediaUrlImgList.value = ruleForm.value.mediaUrl;
        console.log(mediaUrlImgList.value,'===mediaUrlImgList.value===');
      }
    } catch (error) {
      console.log(error);
    }
  }
};
// 售后类型售后原因
const saleType = ref([]);
const getAfterSaleReason = async () => {
  try {
    let res = await AfterSaleReason();
    // console.log(res, "售后类型售后原因");
    if (res.code === 200) {
      saleType.value = res.data;
    }
  } catch (error) {
    console.log(error);
  }
};
emitter.on("orId", (Fn) => {
  if (Fn.id) {
    handelChangValue(Fn.id)
    ruleForm.value.orderNumber = Fn.id
  }
})
onMounted(() => {
  //getAfterList();
  getAfterSaleReason();
});
</script>

<style lang="less" scoped>
.after-content {
  .after-c{
    .numberInput {
        width: 420px;
        height: 40px;
        box-shadow: none;
        position: relative;
        top: 3px;
    }
    .snsinfo{
      width: 120px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border: 1px solid #AAAAAA;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      position: relative;
      top: 3px;
      left: 20px;
      padding-left: 6px;
      /* margin-top: 2px; */
      &:hover{
        color: #ce1200;
        border-color: #ce1200;
        cursor: pointer;
      }
    }
    .snsinfo1{
      position: absolute;
      left: 486px;
      top: 54px;
      width: 266px;
      height: 298px;
      background: #FFFFFF;
      box-shadow: 0px 1px 20px 0px rgba(8,1,3,0.1);
      border-radius: 15px;
      z-index: 99;
      .snsinfo1-1{
        margin: 14px 0 0 18px;
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
      .snsinfo1-2{
        margin: 0px 0 0 18px;
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
    }
  }
  .after-d {
    .el-select {
      width: 582px;
      border: 1px solid #d9d9d9;
    }
    ::v-deep .el-input__wrapper {
      box-shadow: none !important;
    }
  }
  .detail-content {
    width: 681px;
    height: 166px;
    background: #f2f2f2;
    margin-bottom: 30px;
    .detail-top {
      display: flex;
      justify-content: space-between;
      height: 53px;
      border-bottom: 1px solid #e1e1e1;
      margin: 0 25px;
      .top-1 {
        margin-top: 20px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      }
      .top-2 {
        margin-top: 15px;
        img {
          display: inline-block;
          width: 15px;
          height: 15px;
          margin-bottom: 2px;
          margin-right: 5px;
        }
        span {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ce1200;
        }
      }
    }
    .detail-btn {
      display: flex;
      margin: 16px 30px;
      .btn-1 {
        margin-right: 15px;
        img {
          display: inline-block;
          width: 80px;
          height: 80px;
        }
      }
      .btn-2 {
        margin-top: 21px;
        .bnt2-1 {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
        }
        .btn-2-2 {
          display: flex;
          justify-content: baseline;
          .btn-2-3 {
            margin-right: 36px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin-top: 15px;
          }
          .btn-2-4 {
            margin-right: 36px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin-top: 15px;
          }
          .btn-2-5 {
            :nth-child(1) {
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #333333;
            }
            :nth-child(2) {
              font-size: 24px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #333333;
            }
          }
        }
      }
    }
  }
  .after-l {
    display: flex;
    flex-direction: column;

    .l-li {
      li {
        display: flex;
        width: 155px;
        height: 48px;
        line-height: 48px;
        border: 1px solid #d9d9d9;
        margin-right: 20px;
        margin-top: 20px;
        cursor: pointer;

        &.active {
          border-color: #ce1200;
          //background: lighten(#ce1200, 50%);
          color: #ce1200;
          .imgVal {
            display: inline-block;
            width: 24px;
            height: 24px;
            position: relative;
            top: -19px;
            left: 13px;
            background-image: url("./images/dg.png");
            background-repeat: no-repeat;
            background-size: cover;
          }
        }

        .l-title {
          padding-left: 26px;
          img {
            display: inline-block;
            width: 22px;
            height: 22px;
            margin-right: 7px;
            margin-bottom: 5px;
          }
          span {
            display: inline-block;
            font-size: 17px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }
        }
      }
    }
  }
  .after-s {
    display: flex;
    flex-direction: column;
    ::v-deep .el-form-item__content {
      width: 720px;
    }
    .ui-l {
      li {
        display: flex;
        // width: 160px;
        height: 40px;
        line-height: 40px;
        border: 1px solid #d9d9d9;
        margin-right: 14px;
        margin-top: 14px;
        cursor: pointer;
        &.active {
          border-color: #ce1200;
          background: lighten(#ce1200, 50%);
          color: #ce1200;
          position: relative;
          top: 0;
          left: 0;
        }

        .l-title {
          // margin: auto;
          // padding: 0 20px;
          span {
            display: inline-block;
            font-size: 13px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            margin: 0 20px;
          }
        }
      }
    }
  }
  .after-desc {
    display: flex;
    flex-direction: column;
    ::v-deep .el-form-item__label {
      margin-bottom: 12px;
      font-weight: bold;
      color: #333333;
      width: 600px;
    }
    ::v-deep .el-textarea__inner {
      width: 707px;
      height: 80px;
      background: #f2f2f2;
      border: 1px solid #d9d9d9;
    }
    ::v-deep .el-textarea {
      width: 707px;
      background: #f2f2f2;
    }
    ::v-deep .el-textarea__inner:focus {
      box-shadow: none;
    }
    ::v-deep .el-textarea .el-input__count {
      background: #f2f2f2;
    }
  }
  .after-s-s {
    //height: 90px;
    .after-s-s-1 {
      display: flex;
	    flex-direction: column;
      position: relative;
      top: 24px;
      right: 53px;
      .textInput {
        width: 180px;
        height: 40px;
        border: 1px solid #d9d9d9;
        margin-right: 40px;
      }
      .numberInput {
        width: 324px;
        height: 40px;
        box-shadow: none;
        border: 1px solid #d9d9d9;
      }
      ::v-deep .el-input__wrapper {
        border-radius: 0;
        box-shadow: none;
      }
      ::v-deep .el-input__wrapper.is-focus {
        box-shadow: none;
      }
      ::v-deep .el-form-item__label {
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
      ::v-deep .el-input--small .el-input__wrapper {
        padding: 1px 14px;
      }
	    .addressInput{
        width: 612px;
				height: 40px;
		    box-shadow: none;
		    border: 1px solid #d9d9d9;
	    }
    }
  }
  .after-up {
    display: flex;
    flex-direction: column;
	  margin-top:23px;
    ::v-deep .el-form-item__label {
      margin-bottom: 10px;
      font-weight: bold;
      color: #333333;
    }
    ::v-deep .el-upload--picture-card {
      width: 120px;
      height: 120px;
      margin-top: 11px;
      margin-left: 9px;
    }
    ::v-deep .el-upload-list--picture-card .el-upload-list__item {
      width: 120px;
      height: 120px;
    }
    ::v-deep .el-upload-list--picture-card .el-upload-list__item {
      margin: 10px 8px;
    }
  }
  .btn-btn {
    display: inline-block;
    width: 708px;
    height: 54px;
    background: #ce1200;
    border-radius: 5px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    ::v-deep .el-button:focus {
      border-color: none !important;
      border: none;
    }
    ::v-deep .el-button:active {
      border-color: none !important;
    }
  }
}
::v-deep .el-form-item__label {
  display: flex;
  justify-content: normal;
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-top: 10px;
  font-weight: bold;
}
</style>
